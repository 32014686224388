.home-page {
  .sec-2 {
    margin-top: 60px;
    margin-bottom: 72px;

    img {
      filter: grayscale(1) opacity(0.3);
    }
  }
}
