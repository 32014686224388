.site-nav {
  .navbar-toggler {
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  .navbar-collapse {
    .nav-link {
      font-family: Sofia Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #eef1fd;
      position: relative;

      &.active {
        &::after {
          content: '';
          width: 8px;
          height: 2px;
          border-radius: 2px;
          background: #3b82f6;
          display: block;
          position: absolute;
          bottom: 0px;
          left: 40%;

          @include media-breakpoint-down(lg) {
            left: 0;
            width: 5%;
          }
        }
      }
    }
  }

  div[aria-labelledby='features-dropdown'] {
    background-color: #1f2937;

    .dropdown-item {
      padding: 0;
      background-color: transparent;

      @include media-breakpoint-down(md) {
        padding: 0 10px;
      }

      .nav-link {
        &.active {
          &::after {
            left: 10px;
            width: 15%;
          }
        }
      }
    }
  }

  .navbar-collapse.show {
    @include media-breakpoint-down(lg) {
      .dropdown {
        .lngSelectorWrapper {
          background: transparent;
          display: flex;
          align-items: center;

          svg {
            margin-left: auto;
          }
        }
      }

      .extra-links {
        a {
          &.nav-link {
            display: none !important;
          }

          &:last-child {
            width: 100%;
          }
        }
      }

      .nav-link {
        border-bottom: 1px solid #1d284e;
      }
    }
  }
}

// Section1
@import './Section1/Section1.module.scss';
// Section2
@import './Section2/Section2.module.scss';
// Section3
@import './Section3/Section3.module.scss';
// Section4
@import './Section4/Section4.module.scss';
// Section5
@import './Section5/Section5.module.scss';
// Section10
@import './Section10/Section10.module.scss';
