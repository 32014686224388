.sec-5 {
  margin-top: 52px;
  margin-bottom: 52px;

  .navigation-item {
    border-radius: 99px;
    border: 1px solid #e5e7eb;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .testimonial-item {
    .stars {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin-bottom: 20px;
    }
  }
}
