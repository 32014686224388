.sec-3 {
  h2 {
    color: #111827;
    text-align: center;

    /* Desktop/h3 */
    font-family: 'Sofia Sans';
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px; /* 133.333% */
    margin-bottom: 20px;
    margin-top: 52px;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 36px; /* 150% */
    }
  }

  .row {
    .col-lg-4 {
      display: flex;

      .box {
        border-radius: 8px;
        background: #1f2937;
        display: flex;
        padding: 52px 32px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        flex: 1;

        .icon-wrapper {
          border-radius: 98px;
          background: radial-gradient(50% 50% at 50% 50%, #1d4ed8 0%, #374151 100%);
          width: 80px;
          height: 80px;
          min-width: 80px;
          min-height: 80px;
          max-width: 80px;
          max-height: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          color: #93c5fd;
          text-align: center;
          /* Desktop/h4 */
          font-family: 'Sofia Sans';
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px; /* 150% */
        }

        h3 {
          color: #f9fafb;
          text-align: center;
          /* Desktop/h5 */
          font-family: 'Sofia Sans';
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: 24px; /* 133.333% */
          margin: 0 auto 0 auto;

          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        p {
          color: #f9fafb;
          text-align: center;

          /* Desktop/body */
          font-family: 'Sofia Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          margin-bottom: 0;
        }
      }
    }
  }
}
