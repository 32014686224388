.sec-10 {
  margin-bottom: 20px;

  .container-fluid {
    .wrapper {
      padding: 52px 0;
      border-radius: 8px;
      background: #1f2937;

      .swiper {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
      }

      .swiper-slide {
        border-radius: 8px;
        background: #111827;
        box-shadow: 0px 5px 20px 0px rgba(17, 24, 28, 0.12);
        width: 80px;
        height: 80px;
        max-width: 80px;
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
